import React from 'react';
import Hamburger from './Hamburger';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

class NavBarPage extends React.Component{

  hamburgerOpen = false;

  constructor(props) {
        super(props);

        this.state = {
            drizzle: props.drizzle,
            drizzleState: props.drizzleState,
            hamburgerOpen: false
        }
  }

  toogleHamburger = () => {
      this.setState({hamburgerOpen: !this.state.hamburgerOpen});
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
        this.setState({hamburgerOpen: false});
      });
  }
 
  render() {


     return (
         <div>
             <div className="navigation">
                 <ul className={`${(this.state.hamburgerOpen) ? "inline" : "invisible"}`}>
                     <li><Link className="my-nav-link" to="/">Home</Link></li>
                     <li><Link className="my-nav-link" to="/play">Play</Link></li>
                     <li><Link className="my-nav-link" to="/invest">Invest</Link></li>
                     <li><Link className="my-nav-link" to="/about">About</Link></li>
                 </ul>

                 <div className="hamburger" onClick={ (e) =>this.toogleHamburger()}>
                     <Hamburger/>
                 </div>
             </div>
         </div>
     )

    //UNCOMMENT TO USE OLD VERSION WITHOUT BURGER    
    /*return    <div className="main_menu">
                <div className="main_menu_container">
                
                    <Link className="my-nav-link" to="/">Home</Link>
                    <Link className="my-nav-link" to="/play">Play</Link>
                    <Link className="my-nav-link" to="/invest">Invest</Link>
                   
                </div>
            </div>*/

  }

 
}

export default withRouter(NavBarPage);
