import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class SimThunder extends React.Component {
  render() {
    return  (
      <div className="main main-games">
      <HeaderPage title="GGDAPP" subtitle="SIMTHUNDER"/>  
      <div className="box">
        <div className="games_container">
          <div className="game_info sim-thunder">
              <div className="first_col sim-thunder-col">
                <div className="simthunder_icon"></div>
                <a className="button_dapp" target="_blank" href="https://simthunder.com/" rel="noreferrer">Open Dapp</a>
                <Link className="button_dapp" to="/stake/polygon/1">Staking pool</Link>
              </div>
              <div className="second_col">  
                <h2>SimThunder</h2>
                SimThunder is a sim racing assets and collectibles marketplace where sim racers (players of virtual motorsports) can exchange private content items related to sim racing and also collectibles and ownership NFTs.
                <p className="p-row">
                  <a target="_blank" href="https://twitter.com/SimracerCoin" rel="noreferrer"><div className="social_min twitter_min"></div></a>
                  <a target="_blank" href="https://discord.gg/Keuj2uP" rel="noreferrer"><div className="social_min discord_min"></div></a>
                </p>
              </div>
          </div>
          <div className="game_media sim-thunder">
            {/*this.renderSlides()*/}
            <div className="logo-big-simthunder"></div>
            
          </div>
        </div>
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default SimThunder;
