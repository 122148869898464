import React from 'react';
import { DrizzleContext } from "@drizzle/react-plugin";
import { Drizzle, generateStore } from "@drizzle/store";
import StakePage from './StakePage';
import Underconstruction from './Underconstruction'
import Web3 from "web3";
import Web3Modal from "web3modal";
//providers
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Authereum from "authereum";
import Torus from "@toruslabs/torus-embed";
import { Venly } from "@venly/web3-provider";

import StakingGGToken from "../StakingGGToken.json"
import GGTK from "../GGTK.json"

const INFURA_ID = process.env.INFURA_ID;
const VENLY_CLIENT_ID = process.env.VENLY_CLIENT_ID;

var web3;
var isLoadingWallet = true;
var web3Modal;

var NETWORK_IDENTIFIER;
var NETWORK_URL;
var CONTRACT_ADDRESS;
var GGTK_ADDRESS;

class DrizzleLoader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      wrongNetwork: true,
      hasMetamask: false,
      hasValidWallet: false,
      ...props.match.params // pool, network
    }

    NETWORK_URL = process.env[`REACT_APP_${this.state.network.toUpperCase()}_NETWORK_URL`];
    NETWORK_IDENTIFIER = process.env[`REACT_APP_${this.state.network.toUpperCase()}_NETWORK_ID`];
    CONTRACT_ADDRESS = process.env[`REACT_APP_${this.state.network.toUpperCase()}_CONTRACT_ADDRESS`];
    GGTK_ADDRESS = process.env[`REACT_APP_${this.state.network.toUpperCase()}_GGTK_ADDRESS`];

    const providerOptions = {
      /* See Provider Options Section */
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: INFURA_ID, // required
            rpc:  { NETWORK_ID: NETWORK_URL },
            chainId: NETWORK_IDENTIFIER
        }
      }, 
      walletlink: {
        package: WalletLink, // Required
        options: {
          appName: "GGDAPP", // Required
          infuraId: INFURA_ID, // Required unless you provide a JSON RPC url; see `rpc` below
          rpc: NETWORK_URL, // Optional if `infuraId` is provided; otherwise it's required
          chainId: NETWORK_IDENTIFIER, // Optional. It defaults to 1 if not provided
        }
      },
      authereum: {
        package: Authereum // required
      },
      torus: {
        package: Torus, // required
        options: {
          networkParams: {
            host: NETWORK_URL,
            chainId: NETWORK_IDENTIFIER, // optional
            networkId: NETWORK_IDENTIFIER // optional
          }
        }
      },
      venly: {
        package: Venly, // required (previously Arkane network)
        options: {
          clientId: VENLY_CLIENT_ID // required VENLY_CLIENT_ID,"Testaccount" for their staging env
        }
      }
    };

    web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional //cached on WEB3_CONNECT_CACHED_PROVIDER
      providerOptions // required
    });
  }

  componentDidMount = async () => {
    let networkId = 0;
    let isLoggedIn = false;
    let hasMetamask = false;
    let hasValidWallet = false;
    let wallet = null;

    //-----------------------------------
    let provider = null;

    try {
      provider = await web3Modal.connect();
    } catch(e) {
      console.log("Could not get a wallet connection", e);
      isLoadingWallet = false;
    }
    if(provider) {

      // Subscribe to accounts change
      provider.on("provider: accountsChanged", (accounts) => {
        console.log(accounts);
      });

      // Subscribe to chainId change
      provider.on("provider: chainChanged", (chainId) => {
        console.log(chainId);
      });

      // Subscribe to provider connection
      provider.on("connect", (info) => {
        console.log(info);
      });
      // Subscribe to provider disconnection
      provider.on("disconnect", (error) => {
        console.log(error);
      });

      //IF NOT, then something is very wrong
      web3 = new Web3(provider);

      isLoadingWallet = false;

      /*
      //-----------------------------------

      //web3 = window.web3 = new Web3(Web3.givenProvider,{timeout: 60000});//set a timeout of 1 minute on the configuration (should be just initial connection anyway)

      //set bigger timeouts (provided values according to the documentation examples)
      if(web3 && web3.eth) {
        // set the transaction block timeout (default is 50)
        web3.eth.transactionBlockTimeout = 150;
        // set the transaction polling timeout (default is 750) => 12.5 minutes
        web3.eth.transactionPollingTimeout = 1250;  //=> ~20 minutes
      }

      console.log("GET CURRENT PROVIDER, web3.eth.transactionBlockTimeout: " + web3.eth.transactionBlockTimeout + " web3.eth.transactionPollingTimeout: " + web3.eth.transactionPollingTimeout);
      */

      if ((typeof window.ethereum !== 'undefined') && (typeof web3 !== 'undefined') ) {
        hasValidWallet = true;
        if(web3.currentProvider.isMetaMask === true) {
          hasMetamask = true;
        }
      }

      if (hasMetamask || hasValidWallet) {
        networkId = await web3.eth.net.getId();

        console.log('network: ' + networkId);

        console.log("connectWallet...");
        await web3.eth.getAccounts((err, accounts) => {
          if (err != null) console.error("An error occurred: " + err);
          else if (accounts.length > 0) {
            console.log("connectWallet accounts: " + JSON.stringify(accounts));
            isLoggedIn = true;
            wallet = accounts[0];
          }
        });

        // Listen for account changes
        window.ethereum.on('accountsChanged', function (accounts) {
          if (accounts.length > 0) {
            console.log("Account changed to: " + accounts[0]);
            wallet = accounts[0];
            // Optionally reload the page to refresh the application state
            window.location.reload();
          } else {
            // Handle case when user has locked MetaMask or no accounts are available
            console.log("User is not connected to MetaMask");
            isLoggedIn = false;
            // Optionally handle logout or show a notification to the user
          }
        });
      }
    }

    this.setState({
      isLoggedIn,
      hasMetamask,
      hasValidWallet,
      wrongNetwork: (networkId != NETWORK_IDENTIFIER),
      wallet
    });
  }

  render() {
    const { state } = this;

    if(isLoadingWallet) return null;

    if (!web3 && isLoadingWallet) {
      return (<div id="wait-div" className="spinner-outer"><div className="spinner"></div></div>);
    }

    if ( (state.hasMetamask || state.hasValidWallet) && state.isLoggedIn) {

      if(state.wrongNetwork) {
        return <Underconstruction hasMetamask={true} isLoggedIn={true} wrongNetwork={state.wrongNetwork} networkId={NETWORK_IDENTIFIER} web3={web3} />
      }

      const drizzleOptions = {
        contracts: [ 
          {
            contractName: 'StakingGGToken',
            web3Contract: new web3.eth.Contract(StakingGGToken.abi, CONTRACT_ADDRESS)
          },
          {
            contractName: 'GGTK',
            web3Contract: new web3.eth.Contract(GGTK.abi, GGTK_ADDRESS)
          }]
      }

      const drizzleStore = generateStore(drizzleOptions);
      const drizzle = new Drizzle(drizzleOptions, drizzleStore);

      return (
        <DrizzleContext.Provider drizzle={drizzle}>
          <DrizzleContext.Consumer>
            {drizzleContext => {
              const { drizzle, drizzleState, initialized } = drizzleContext;

              if (!initialized) {
                return <div id="wait-div" className="spinner-outer"><div className="spinner"></div></div>
              }

              return <StakePage drizzle={drizzle} drizzleState={drizzleState} wallet={state.wallet} pool={state.pool} networkId={NETWORK_IDENTIFIER} />
            }}
          </DrizzleContext.Consumer>
        </DrizzleContext.Provider>
      );
    }

    return <Underconstruction hasMetamask={state.hasMetamask} isLoggedIn={state.isLoggedIn} wrongNetwork={state.wrongNetwork} networkId={NETWORK_IDENTIFIER} web3={web3} />
  }
}
export default DrizzleLoader;