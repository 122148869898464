import React from 'react';
import { Button } from 'react-bootstrap'; // Check out drizzle's react components at @drizzle/react-components
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'
import UIHelper from "../uihelper";
import AlertComponent from '../components/AlertComponent';
import { Link  } from 'react-router-dom';

let EXPORER_PATH;
let NETWORK_NAME;
let GGTK_CONTRACT_ADDRESS;
let GGTK_CONTRACT_ADDRESS_SHORTER;
let GGTK_STAKING_CONTRACT_ADDRESS;
let GGTK_STAKING_CONTRACT_ADDRESS_SHORTER;
let GGTK_CONTRACT_ADDRESS_EXPLORER;
let GGTK_STAKING_CONTRACT_ADDRESS_EXPLORER;

let web3;

let transactionInProgress = false;
let isRefreshing = false;
let refreshInterval = null;
const REFRESH_INTERVAL_SECONDS = 60;//1 minute(s) . Try to do more local updates instead cause th netwrok is not always up to date as fast
const WARNING_MESSAGE = "(*) A new contract will be deployed in Polygon. Please unstake all your tokens and claim rewards until April 29th.";
//"(*) Due to the congestion problems in Polygon network we changed the rewards distribution frequency to once a day. The amount remains 1440 GGTK/day.";
const WARNING_TITLE = "Contract Migration"; 
//Network Congestion
//change this if we don´t need the message anymore
let SHOW_WARNING_MESSAGE = false;
const LOCAL_NETWORK = "5777";

let lastRefreshTime = 0;

const TOKEN_SYMBOL_MAPPING = {
  GGTK: "gg-token", 
  SRC: "simracer-coin"
}

const POOL_INFO = {
  137: {
    "General": {created_date: "2021-12-31", totalSupply: 1500000, title: "Polygon Pioneers Staking Pool", info: "In this pool you can <span style=\"font-weight:bold;\">stake GGTK to earn GGTK</span>. There are 1500000 GGTK to be distributed by the platform early adopters, 1440 distributed daily.<br /><br /><span style=\"font-weight:bold;\">The staking period for this pool has ended, stay tuned for the opening of the next one!</span>" },
    "Simracer Coin Pool": { url: "/simthunder", created_date: "2022-09-23", info: "In this pool you can <span style=\"font-weight:bold;\">stake GGTK to earn SRC</span>, Simracer Coin the token of Simthunder, a sim racing assets marketplace. This is a GG DApp project. There are total of 730000 SRC to be distributed, 1000 distributed daily.<br /><br /><span style=\"font-weight:bold;\">The staking period for this pool has ended, stay tuned for the opening of the next one!</span>" },
    "Pirates 2048 Pool": { url: "/pirates", created_date: "2023-12-08", info: "In this pool you can <span style=\"font-weight:bold;\">stake GGTK to earn P48PRE</span>, Pirates 2048 game pre-Token. This is a GG DApp project. There are 450000 P48PRE to be distributed, 2500 distributed daily.<br /><br /><span style=\"font-weight:bold;\">The staking period for this pool has ended, stay tuned for the opening of the next one!</span>" },
    "Crypto Chaser Pool": { url: "/cryptochaser", created_date: "", info: "" }
  }, 
  42161: {
    "General": {created_date: "2024-09-09", totalSupply: 129600, title: "Arbitrum Pioneers Staking Pool", info: "In this pool you can <span style=\"font-weight:bold;\">stake GGTK to earn GGTK</span>. There are 129600 GGTK to be distributed by the platform early adopters in Arbitrum during a 3 month period, 1440 distributed daily. This pool has a cap of 500000 GGTK.<br /><br /><span style=\"font-weight:bold;\">The staking period for this pool has ended, stay tuned for the opening of the next one!</span>" },
    "General 2": { title: "Arbitrum Pioneers Staking Pool #2", openAt: 1734019200000, info: "In this pool you can <span style=\"font-weight:bold;\">stake GGTK to earn GGTK</span>. There are 129600 GGTK to be distributed by the platform early adopters in Arbitrum during a 3 month period, 1440 distributed daily. This pool has a cap of 300000 GGTK.<br /><br /><span id=\"timerSpan\" style=\"font-weight:bold;\">This pool will open on December 12th at 4:00 PM UTC.</span>" },
    "Simracer Coin Pool": { url: "/simthunder", created_date: "", info: "In this pool you can <span style=\"font-weight:bold;\">stake GGTK to earn SRC</span>, Simracer Coin the token of Simthunder, a sim racing assets marketplace. This is a GG DApp project. There are total of 730000 SRC to be distributed, 1000 distributed daily." },
  }
}

const PRETOKENS = {"CHSPRE": {tge: "Q4 2024", price: "0.20"}, "P48PRE": {tge: "Q3 2024", price: "0.15"}};

//https://api.coingecko.com/api/v3/coins/gg-token

function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function callWithRetry(callObject, options = {}) {
  const maxAttempts = 10;
  let result, attempt = 0;
  
  do {
    try {
      [result,] = await Promise.all([callObject.call(options), sleep(100)]);
    } catch(err) {
      console.error(`Attempt ${attempt + 1} failed: ${err.message}`);
      await sleep(1000 * (attempt+1));
    }
  } while(!result && ++attempt < maxAttempts);

  if(maxAttempts === attempt)
    throw new Error("Exceeded maximum retries");

  return result;
}

class StakePage extends React.Component{  

  constructor(props) {
    super(props);

    this.state = {
      contractStaking: null,
      contractGGTK: null,
      currentAccount: props.wallet || null,
      poolOwner: "",
      stakeAmount: 0,
      lockedPeriod: 7,
      minAmount: 10,
      maxAmount: 5000,    //max i can stake
      maxPerStaker: 5000, //max allowed per user (as per last contract)
      maxStaking: 0,
      balance: 0,
      totalStakes: 0,
      totalParticipants: 0,
      isStakeholder: false,
      canStake: false,
      currentStake: 0,
      availableRewards: 0,
      availableToUnstake: 0,
      lockedPercentage: "",
      approved: false, //approve allowance amount
      remainingRewards: 0,
      totalSupply:0,
      tokenAddr: null,
      tokenSymbol: null,
      tokenName: null,
      poolName: null,
      distribution: null,
      rewardPercentage: 0,
      showMessage: SHOW_WARNING_MESSAGE,
      messageOptions: {show: false, title:'', variant:'sucess',message:''},
      markets: [],
      apy: 0,
      activePool: false
    }

    web3 = props.drizzle.web3;
  }

  componentDidMount = async () => {

    const { pool, networkId } = this.props;

    window.addEventListener("beforeunload", this.onUnload);

    UIHelper.showSpinning("Please wait...");

    //get contracts and their addresses for explorer
    const contractStaking = await this.props.drizzle.contracts.StakingGGToken;

    let poolInfo = null;
    console.log('will get pool id ', pool);

    poolInfo = await callWithRetry(contractStaking.methods.getPool(pool));

    NETWORK_NAME = networkId == "137" ? "Polygon" : "Arbitrum";
    EXPORER_PATH = networkId == "137" ? "https://polygonscan.com/address/" : "https://arbiscan.io/address/";

    if(poolInfo) {
      GGTK_STAKING_CONTRACT_ADDRESS = contractStaking.address;
      GGTK_STAKING_CONTRACT_ADDRESS_EXPLORER = EXPORER_PATH + GGTK_STAKING_CONTRACT_ADDRESS;
      //shorter version
      GGTK_STAKING_CONTRACT_ADDRESS_SHORTER = GGTK_STAKING_CONTRACT_ADDRESS.substring(0,10).concat('...').concat(GGTK_STAKING_CONTRACT_ADDRESS.substring(30,GGTK_STAKING_CONTRACT_ADDRESS.length));
      
      const contractGGTK = await this.props.drizzle.contracts.GGTK;
      GGTK_CONTRACT_ADDRESS = contractGGTK.address;
      GGTK_CONTRACT_ADDRESS_EXPLORER = EXPORER_PATH + GGTK_CONTRACT_ADDRESS;
      //shorter version
      GGTK_CONTRACT_ADDRESS_SHORTER = GGTK_CONTRACT_ADDRESS.substring(0,10).concat('...').concat(GGTK_CONTRACT_ADDRESS.substring(30,GGTK_CONTRACT_ADDRESS.length));
      
      const currentAccount = await this.props.drizzleState.accounts[0];

      //let approved = this.checkIfAlreadyApproved(currentAccount);

      let minPerStaker =  web3.utils.fromWei(new web3.utils.BN(await callWithRetry(contractStaking.methods.getMinPerStaker())));
      let maxPerStaker =  web3.utils.fromWei(new web3.utils.BN(await callWithRetry(contractStaking.methods.getMaxPerStaker())));
      let maxStaking = web3.utils.fromWei(new web3.utils.BN(await callWithRetry(contractStaking.methods.getMaxStaking())));
      let lockedPeriod = (await callWithRetry(contractStaking.methods.getLockedPeriod())) / 24 / 60 / 60;

      let rewardPercentage = await callWithRetry(contractStaking.methods.getRewardPercentage());

      const tokenAddress = poolInfo[0];
      let shorterTokenAddr = tokenAddress.substring(0,10).concat('...').concat(tokenAddress.substring(30,tokenAddress.length));

      const tokenSymbol = poolInfo[2];
      const poolName = POOL_INFO[networkId][poolInfo[3]].title || poolInfo[3] + ` (${NETWORK_NAME})`;
      const totalSupply = POOL_INFO[networkId][poolInfo[3]].totalSupply || web3.utils.fromWei(new web3.utils.BN(poolInfo[7]));
      const distribution = web3.utils.fromWei(new web3.utils.BN(poolInfo[6])) * (pool == 0 ? rewardPercentage / 100 : 1);
      let activePool = poolInfo[8];

      if(activePool && POOL_INFO[networkId][poolInfo[3]].openAt) {
        activePool = Date.now() >= POOL_INFO[networkId][poolInfo[3]].openAt;
      }

      console.log(`Active pool: ${activePool}`);
      // compute remaing rewards
      let remainingRewards;
      const created_date = POOL_INFO[networkId][poolInfo[3]].created_date;
      if(created_date) {
        const d1 = new Date(created_date);
        const d2 = new Date();
        const daysInDistribution = Math.trunc((d2 - d1) / (1000 * 60 * 60 * 24));
        
        remainingRewards = totalSupply - (daysInDistribution * distribution);
      } else {
        remainingRewards = await callWithRetry(contractStaking.methods.remainingRewards(pool));
        remainingRewards = web3.utils.fromWei(remainingRewards);
      }

      let remainingRewardsInDays = Math.trunc(remainingRewards / distribution);

      if(isNaN(remainingRewards) || remainingRewards < 0) {
        remainingRewards = 0;
        remainingRewardsInDays = 0;
      }

      console.log("available rewards:", web3.utils.fromWei(await callWithRetry(contractStaking.methods.remainingRewards(pool))));

      this.setState({
        contractGGTK, 
        contractStaking, 
        currentAccount,
        minAmount: parseFloat(minPerStaker),
        maxAmount: parseFloat(maxPerStaker),
        maxPerStaker,
        maxStaking,
        lockedPeriod,
        remainingRewards,
        remainingRewardsInDays,
        totalSupply,
        tokenAddr: tokenAddress,
        shorterTokenAddr,
        tokenName: poolInfo[1],
        tokenSymbol,
        poolName,
        poolActive: true,
        poolOwner: poolInfo[9],
        distribution,
        rewardPercentage,
        activePool,
        poolInfo: POOL_INFO[networkId][poolInfo[3]].info,
        poolUrl: POOL_INFO[networkId][poolInfo[3]].url
      });

      this.loadMarkets(tokenSymbol.toUpperCase());
      this.refreshBalance();

      UIHelper.hideSpinning();

      refreshInterval = setInterval(() => {
          //refresh balance after X seconds
          if(lastRefreshTime > 0) {
            //this timestamp is only update when some operation succeeds, so in this case we check how long that was
            //if it was more than 1 minute(s) ago, we can safely assume that teh contract will already give updated values,
            //which means is probably safe to do an automatic refresh at this point
            let now = new Date().getTime();
            if( !isRefreshing && Math.abs(now - lastRefreshTime) >= (REFRESH_INTERVAL_SECONDS * 1000) ) {
              //update the refresh time
              lastRefreshTime = now;
              this.refreshBalance();
            }
          } else if(!isRefreshing) {
            this.refreshBalance();
          }

      }, REFRESH_INTERVAL_SECONDS * 1000);


      if(SHOW_WARNING_MESSAGE) {
        setTimeout( ()=> {
            SHOW_WARNING_MESSAGE = false;
            this.setState({showMessage: false});
        },10 * 1000) //auto hide after 10 secs
      }
    } else {
      this.setState({pool: -1, poolActive: false});
    }
  }

  /**
   * Loads the markets for a given token (from coingecko public API)
   */
  loadMarkets = async (symbol) => {
    const uri = "https://api.coingecko.com/api/v3/coins/" + TOKEN_SYMBOL_MAPPING[symbol];
      const result = await fetch(uri);
      const object = await result.json();
      if(object && object.tickers && object.tickers.length>0) {
        let marketsArray = [];
        for(const ticker of object.tickers) {
          console.log(ticker);
          if(ticker.market) {
            if(ticker.trade_url) {
              ticker.market.trade_url = ticker.trade_url;
              ticker.market.exchange_url = new URL(ticker.trade_url).hostname;

              marketsArray.push(ticker.market);
            }
          }
        }
        this.setState({markets: marketsArray});
      }
  }

  componentWillUnmount = async() => {
    if(refreshInterval) {
      clearInterval(refreshInterval);
    }
    //this.removeAllowances();
    //localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");//show the wallet connection modal next time

    transactionInProgress = false;

    //make sure this does pass to another route
    UIHelper.hideSpinning();

    window.removeEventListener("beforeunload", this.onUnload);
  } 


  onUnload = (event) => {

    if (transactionInProgress) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = ''
        }
        return '';
    }
    
  }

  calculateLockedPercentage() {
    if(!this.state.currentStake || !this.state.isStakeholder) {
      this.setState({lockedPercentage: "0%"});
      return;
    }
    else if(this.state.currentStake && !this.state.availableToUnstake) {
      this.setState({lockedPercentage: "100%"});
      return;
    }
    
    let unlockedRatio = Math.trunc(Math.abs(this.state.availableToUnstake / this.state.currentStake) * 100);
    let value = (100 - unlockedRatio) + "%";
    this.setState({lockedPercentage: value});
  }

  printMyCurrentStake() {
    let stakeValue = Number(this.state.currentStake);
    if(Math.round(stakeValue)!==stakeValue) {
      stakeValue = Number(stakeValue).toFixed(2);
    }
    if(this.state.isStakeholder) {
      return <tr>
            <td>My Current Stake</td>
            <td className="padding-left10px">{stakeValue} GGTK ({this.state.lockedPercentage} locked)</td>
            </tr>
    }
    return <tr className="d-done">
    <td>My Current Stake</td>
    <td className="padding-left10px">{stakeValue} GGTK</td>
    </tr>
  }



  //print the exchanges were the token is listed
  printMarketExchanges() {
    if(this.state.markets.length > 0 ) {
      return (
        [<tr className="trBorderedDown">
          <td><span className="highlight-span">Exchanges</span></td><td className="tdNoBorderLeft padding-left10px">&nbsp;</td>
        </tr>].concat(this.state.markets.map( (market) => {
        return <tr className="tdFullBordered">
                <td className="tdFullBordered td-center-text">{market.name}</td><td className="tdFullBordered padding-left10px td-center-text"><a target="_blank" rel="noreferrer" href={market.trade_url}>https://{market.exchange_url}</a></td>
              </tr>}))
      )
    } else if(this.state.tokenSymbol in PRETOKENS) {
      return(
        [<tr>
          <td colSpan={2}>Pre-token: this is not the final token, holders will get air-dropped when the final token is deployed.</td>
        </tr>,
        <tr className="tdFullBordered">
          <td className="tdFullBordered td-center-text"><span>Estimated TGE:</span></td><td className="tdNoBorderLeft padding-left10px td-center-text">{PRETOKENS[this.state.tokenSymbol].tge}</td>
        </tr>,
        <tr className="tdFullBordered">
          <td className="tdFullBordered td-center-text"><span>Estimated sale price:</span></td><td className="tdNoBorderLeft padding-left10px td-center-text">${PRETOKENS[this.state.tokenSymbol].price}</td>
        </tr>]);
    }
  }

    /**if(this.state.isStakeholder) {
      return <tr>
            <td>My Current Stake</td>
            <td>{stakeValue} GGTK ({this.state.lockedPercentage} locked)</td>
            </tr>
    }
    return <tr className="d-done">
    <td>My Current Stake</td>
    <td>{stakeValue} GGTK</td>
    </tr>*/
  

  renderTable() {
    const { state, props } = this;
    if(state.activePool && state.poolInfo.indexOf("timerSpan") > 0) {
      state.poolInfo = state.poolInfo.substring(0, state.poolInfo.indexOf("<br /><br /><span id=\"timerSpan\""));
    }
    return (
      <div className="center_table_container">
      <table className="center_table">
        <tbody>
        {/*<tr>
         <td>Pool</td>
         <td className="padding-left10px">{this.state.poolName}</td>
    </tr>*/}
        <tr className="noBorders">
          <td><span className="highlight-span">{state.poolName}</span></td><td className="tdNoBorderLeft padding-left10px">&nbsp;</td>
        </tr>
        <tr className="noBorders">
          <td colSpan={2}>
            <span dangerouslySetInnerHTML={{__html: state.poolInfo}}></span>
          </td>
        </tr>
        {state.poolUrl &&
            <tr className="noBorders">
            <td className="noBorders" colSpan={2}>
              <Link to={state.poolUrl} className="connect-btn btn project-info-btn">View Project Info</Link>
            </td>
            </tr>
          }
          <tr className="noBorders">
            <td className="noBorders">
              &nbsp;
            </td>
            <td className="noBorders">&nbsp;</td>
            </tr>
        <tr className="noBorders">
          <td><span className="highlight-span">Staking details</span></td><td className="tdNoBorderLeft padding-left10px">&nbsp;</td>
        </tr>
        <tr>
          <td>Token type</td>
          <td className="padding-left10px">ERC-20 ({NETWORK_NAME})</td>
        </tr>
        <tr>
          <td>Token name & ticker</td>
          <td className="padding-left10px">{state.tokenName} ({state.tokenSymbol})</td>
        </tr>
        <tr>
          <td>Rewards supply</td>
          <td className="padding-left10px">{state.totalSupply} {state.tokenSymbol}</td>
        </tr>
        <tr>
            <td>Remaining rewards</td>
            <td className="padding-left10px">{state.remainingRewards} {state.tokenSymbol} ({state.remainingRewardsInDays} days left)</td>
        </tr>
          { props.pool !== 0 &&
          <tr>
             <td>Token contract</td>
             <td className="padding-left10px"><a target="_blank" rel="noreferrer" href={EXPORER_PATH + state.tokenAddr}>{state.shorterTokenAddr}</a></td>
          </tr>
          }
          <tr>
             <td>GGTK contract</td>
             <td className="padding-left10px"><a target="_blank" rel="noreferrer" href={GGTK_CONTRACT_ADDRESS_EXPLORER}>{GGTK_CONTRACT_ADDRESS_SHORTER}</a></td>
          </tr>
          <tr>
            <td>Staking contract</td>
            <td className="padding-left10px"><a target="_blank" rel="noreferrer" href={GGTK_STAKING_CONTRACT_ADDRESS_EXPLORER}>{GGTK_STAKING_CONTRACT_ADDRESS_SHORTER}</a></td>
          </tr>
          {/*<tr>
              <td>Minimum number of GGTK staking</td>
              <td className="padding-left10px">{this.state.minAmount} GGTK</td>
        </tr>*/}
          <tr>
             <td>Total staking</td>
             <td className="padding-left10px">{Math.trunc(state.totalStakes)} GGTK</td>
          </tr>
          <tr>
              <td>Total participants</td>
              <td className="padding-left10px">{state.totalParticipants}</td>
          </tr>
          <tr>
              <td>Token locked days</td>
              <td className="padding-left10px">{state.lockedPeriod}</td>
          </tr>
          <tr>
              <td>Distribution policy</td>
              <td className="padding-left10px">{state.distribution} {state.tokenSymbol} each 24 hours</td>
          </tr>
          
          {this.printAPYOptions()}

          {this.printMyCurrentStake()}

          <tr className="noBorders">
            <td className="noBorders" colSpan={2}>
              &nbsp;
            </td>
            </tr>

          {this.printMarketExchanges()}
          </tbody>
        </table>
        </div>
    )
  }

  checkIfAccountIsPoolOwner() {
    const { state } = this;
    const isPoolOwner = (state.currentAccount!== null && state.poolOwner !== null) && 
      state.currentAccount.toLowerCase() === state.poolOwner.toLowerCase();
    return isPoolOwner;
  }

  printAPYOptions() {
    //Retirar restake rewards na pool SRC (only shows button if the pool is the main (0))
    const isMainPool = (this.props.pool == 0);
    //se for o owner da pool, os rewards são GGTK e não na moeda da pool
    let symbol = this.state.tokenSymbol;
    const isOwner = this.checkIfAccountIsPoolOwner();
    if(isOwner && !isMainPool) {
      symbol = "GGTK";
    }
    
    return <tr>
        <td>Current daily reward</td>
        <td className="padding-left10px">{this.state.apy} {symbol} each 100 GGTK</td>
        </tr>
  }

  printRewardsOptions() {

    //Retirar restake rewards na pool SRC (only shows button if the pool is the main (0))
    const isMainPool = this.props.pool == 0 || this.state.tokenAddr === GGTK_CONTRACT_ADDRESS;
    //se for o owner da pool, os rewards são GGTK e não na moeda da pool
    let symbol = this.state.tokenSymbol;
    const isOwner = this.checkIfAccountIsPoolOwner();
    if(isOwner && !isMainPool) {
      symbol = "GGTK";
    }

    const {availableRewards, minAmount} = this.state;

    if(availableRewards > 0) {
        let cannotRestake = availableRewards < minAmount;
        //just claim
        return <div className="rewards-div">
          <Button onClick={ (e) => {this.claimRewards(e)}} className="connect-btn btn-rewards btn-full-width">Claim Rewards</Button>
          <span className="available_rewards">(available rewards: {availableRewards} {symbol})</span>
          {isMainPool &&
            <Button disabled={cannotRestake} onClick={ (e) => {this.restakeRewards(e)}} className={`connect-btn btn-restake btn-full-width ${cannotRestake ? "btn-disabled" : ""}`}>Restake Rewards</Button>
          }
          
          </div>
    }
    return <div className="rewards-div">
          <Button disabled className="connect-btn btn-rewards btn-full-width btn-disabled">Claim Rewards</Button>
          <span className="available_rewards">(available rewards: <em>{availableRewards}</em> {symbol})</span>
          {isMainPool &&
            <Button disabled onClick={ (e) => {this.restakeRewards(e)}} className="connect-btn btn-restake btn-full-width btn-disabled">Restake Rewards</Button>
          }
          </div>
  }

  renderButtons() {
    
        return <div className="add_stake">
              <input className="stake_input mt-2" id="stake_input" step="1" placeholder="Please enter the amount" type="number" onChange={(e) => {this.handleAmountChange(e)}} min={this.state.minAmount} max={this.state.maxAmount} value={this.state.stakeAmount}></input>
              {this.getProperErrorMessage()}
              <span className="available_rewards">(available balance: {Number(this.state.balance).toFixed(2)} GGTK)</span>
              {this.printStakeButton()}
              {this.printRewardsOptions()}
              </div>
  }

  getProperErrorMessage = () => {
    const { state } = this;

    let currentStake = parseFloat(state.currentStake);
    let newStake = parseFloat(state.stakeAmount);
    let min = state.minAmount;
    let max = parseFloat(state.maxPerStaker);

    if(newStake === 0) return;
    
    //console.log("this.state.stakeAmount " + this.state.stakeAmount + "this.state.minPerStaker "  + this.state.minAmount + " max per staker: " + this.state.maxPerStaker + "current stake: " + this.state.currentStake);
    if(newStake < min) {
      return <label id="label_input_validator" className="error-message" htmlFor="stake_input">(*) Min stake is {state.minAmount} GGTK</label>
    }

    if(newStake > max || currentStake > max || currentStake + newStake > max) {
      return <label id="label_input_validator" className="error-message" htmlFor="stake_input">(*) Max stake on this pool is {state.maxPerStaker} GGTK</label>
    }
    
    if(newStake > parseFloat(state.balance)) {
      return <label id="label_input_validator" className="error-message" htmlFor="stake_input">(*) The amount exceeds your available balance</label>
    }
    
    if(parseFloat(state.totalStakes) + newStake > parseFloat(state.maxStaking)) {
      return <label id="label_input_validator_max" className="error-message" htmlFor="stake_input">(*) Staking pool as reached maximum capacity</label>
    }
  }

  printStakeButton = () => {
    const isPoolOwner = this.checkIfAccountIsPoolOwner();
    const { canStake, stakeAmount, minAmount, maxAmount } = this.state;

    if(canStake && ( (stakeAmount >= minAmount) && (stakeAmount <= maxAmount) ) ) {
      return <Button disabled={isPoolOwner} onClick={this.stake} className="connect-btn btn-stake">Stake GGTK</Button>
    }
    return <Button disabled className="connect-btn btn-stake btn-disabled">Stake GGTK</Button>
  }

  handleAmountChange = (event) => {
    const { state } = this;
    let { value, min, max } = event.target;
    let newStake = Number(value);
    let totalStake = Number(this.state.currentStake) + newStake;
    let maxStake = Number(max);

    let canStake = state.remainingRewards > 0 && state.activePool && (newStake >= Number(min) && newStake <= maxStake) && totalStake <= state.maxPerStaker;
    this.setState({canStake, stakeAmount: newStake});
  }

  renderUnstakeButton = () => {
    if(this.state.isStakeholder) {
      return (
        <Button disabled={this.state.availableToUnstake === 0} className={`connect-btn-unstake ${(this.state.availableToUnstake) === 0 ? "btn-disabled" : ""}`} onClick={ (e) => {this.unstake(e)}}>Unstake ({this.getAvailableToUnstakeAmount()})</Button>
      )
    }
    return "";
  }

  getAvailableToUnstakeAmount = () => {
    if(Number(this.state.availableToUnstake) > 0) {
      return Number(this.state.availableToUnstake).toFixed(2);
    }
    return 0;
  }

  renderStakeButton = () => {
    return (
      <div className="tokenomics">
        <div className="connect">
          {this.renderButtons()}
        </div>
        {this.renderUnstakeButton()}
       </div>
    )
    
  }


  calculateAPY = () => {
    try {
      const totalStaking = Math.trunc(this.state.totalStakes);
      const totalRewardsPerDay = Number(this.state.distribution);

      // Calculate APY
      const apy = Number(100 / (100+totalStaking) * totalRewardsPerDay).toFixed(2)

      this.setState({apy: apy});
    } catch (e) {
        console.log(e);
    }
  };

  renderWrongPoolInfo = () => {

    return (
    <div className="main stake">
            <HeaderPage title="Stake" subtitle="Staking details"/>  
            <div className="box small-margin-top">
                <div className="error_div">Pool Error
                    <div className="connect-metamask">
                        <span>The pool you´re trying to reach does not exist or is not valid one!</span>
                    </div>
                </div>
            </div>
            <FooterPage extraClass="stake-footer"/>  
      </div>
    )
  }

  render() {

    if(this.props.pool == -1 || this.state.poolActive === false) {
      UIHelper.hideSpinning();
      return this.renderWrongPoolInfo();
    }
    
    return  (
      
      <div className='full_width'>
        {this.renderWarningMessageIfAny()}
        {this.renderGenericMessageIfAny()}
        <div className="main stake">
            <HeaderPage title="Stake" subtitle="Staking details" wallet={this.state.currentAccount}/> 
            <div className="box stake-page">
              <div className="stake_container"> 
              {this.renderTable()}

              {this.renderStakeButton()}
          
              {/*this.renderUnstakeButton()*/}
              </div>
            </div>
          <FooterPage extraClass="stake-footer"/>  
        </div>
        {/* warn user if some transaction is in progress and he attempts to leave (doesn´t work if not on state) 
        <Prompt when={transactionInProgress} message="It seems there is a transaction still running! Are you sure you want to leave?"/>
        */}
      </div>
    );
  }

  getTotalStakes =  async () => {
    const totalStakes = web3.utils.fromWei(new web3.utils.BN(await callWithRetry(this.state.contractStaking.methods.totalStakes(this.props.pool))));
    this.setState({totalStakes});
  }

  getTotalParticipants =  async () => {
    const totalParticipants = await callWithRetry(this.state.contractStaking.methods.totalParticipants(this.props.pool));
    this.setState({totalParticipants});
  }

  //last thing that is done on refreshBalance()
  checkIfStakeHolder = async () => {
    const data = await callWithRetry(this.state.contractStaking.methods.isStakeholder(this.state.currentAccount, this.props.pool));
    const isStakeholder = data[0];
    console.log("is staker? " + isStakeholder + " at position " + data[1]);
    this.setState({isStakeholder});

    if(isStakeholder) {
      await this.getMyStakeAmount();
      await this.checkIfCanRemoveStake();
    } else {
      this.setState({availableToUnstake: 0, currentStake: 0});
    }
    await this.checkAvailableRewards();

    this.calculateLockedPercentage();
    this.forceUpdate();
  }

  getMyStakeAmount = async () => {
    const { state, props } = this;

    if(state.isStakeholder) {
      //let currentBalance = this.state.balance;
      const currentStake = web3.utils.fromWei(
        await callWithRetry(state.contractStaking.methods.stakeOf(state.currentAccount, props.pool)));
      console.log("My stake is: " + currentStake);
    
      this.setState({currentStake});
    }
  }

  checkAvailableRewards = async () => {
    const myRewards = web3.utils.fromWei(
      new web3.utils.BN(await callWithRetry(this.state.contractStaking.methods.rewardOf(this.state.currentAccount, this.props.pool))));
    console.log("my current rewards for pool " + this.props.pool + " are: " + myRewards);
    this.setState({availableRewards: Math.round(myRewards*100)/100});
  }

  checkIfCanRemoveStake = async () => {
    //let nowInSeconds = Math.trunc(new Date().getTime()/1000);
    const unstakeble = await callWithRetry(this.state.contractStaking.methods.canUnstakeAny(this.state.currentAccount, this.props.pool));
    const availableToUnstake = web3.utils.fromWei(new web3.utils.BN(unstakeble[1]));
    console.log("available to unstake: " + availableToUnstake);
    this.setState({availableToUnstake});
  }

  readAllowances = () => {
    let allowances = localStorage.getItem("ggtk_allow_v2");
    if(allowances) {
      allowances = JSON.parse(allowances);
    }
    return allowances;
  }

  updateAllowances = async () => {
    this.writeAllowances(
      web3.utils.fromWei(await callWithRetry(this.state.contractGGTK.methods.allowance(this.state.currentAccount, this.state.contractStaking.address))));
  }

  //after unstake we need to update the amount to prepare for a subsequent stake
  removeAllowances = () => {
    localStorage.removeItem("ggtk_allow_v2");
  }

  writeAllowances = (maxApproved) => {
 
    const {state} = this;

    let allowances = localStorage.getItem("ggtk_allow_v2");//use new key to avoid legacy issues
    let data = {wallet: state.currentAccount, amount: maxApproved};

    if(!allowances) {
      //nothing exists yet
      allowances = [];
      allowances.push(data);
    } else {
    
      allowances = JSON.parse(allowances);
      let found = false;
      //find previous record if any
      for(let element of allowances ) {

        //when the contract changes the previous approval is no longer valid either
        if(element.wallet.toLowerCase() == state.currentAccount.toLowerCase()) {
            found = true;
            element.amount = maxApproved;
            break;
        }
      }

      //should not happen, but in any case double check
      if(!found) {
        allowances.push(data);
      }
    }
    //re-write it again
    localStorage.setItem("ggtk_allow_v2", JSON.stringify(allowances));
  }

  //check is max amount was already approved or not, if not we should ask new approval
  checkIfAlreadyApproved = (newAmountToApprove) => {

    const {state} = this;

    let allowances = this.readAllowances();
    let totalStake = Number(newAmountToApprove); //Number(state.currentStake) + Number(newAmountToApprove);
 
    if(allowances && allowances.length) {
      for(let element of allowances) {

        if( element.wallet.toLowerCase() === state.currentAccount.toLowerCase()
         && Number(element.amount) >= totalStake ) {
          return true;
        }
      }
    }
    return false;
  }

  approveAllowance = async () => {
    try {

      const amount = web3.utils.toWei(String(this.state.maxPerStaker)); 

      transactionInProgress = true;

      let result = await this.state.contractGGTK.methods.approve(this.state.contractStaking.address, amount)
        .send(await this.buildRequestParams("approve"));
      
      transactionInProgress = false;

      /**
       * {
        "transactionHash": "0x591919cc8e2e4c618a6780ab25a587fd160684c561371779a936ad1cf4984fa6",
        "transactionIndex": 0,
        "blockHash": "0xfd19b19d6da598b6871bf41e8dff5a108f0026fc66c221e9f3e3107fc00d579d",
        "blockNumber": 30,
        "from": "0x5c2ea0a73d040b2f0dc49ffb077591a5e1cad144",
        "to": "0xca1142c173c7acf381af03ecf048ec78caa0eeb0",
        "gasUsed": 25605,
        "cumulativeGasUsed": 25605,
        "contractAddress": null,
        "status": true
      }
      */
      if(result && result.status && result.transactionHash!=null && result.blockNumber!=null) {

        this.setState({approved: true});
        this.updateAllowances();
        
        return {status: true, error: null};
      }

      return {status: false, error: null};
    }
    catch(err) {
      //ex: error 401 is user denied!
      transactionInProgress = false;
      return {status: false, error: err};
    }
  }

  /**
   * TODO implement retry mechanism
   * @param {*} amount 
   * @param {*} rawAmount 
   */
  performStakeAfterApproval = async (amount, rawAmount) => {
    //already approved MAX allowance of 1000 (state.maxAmount) GGTK
    let self = this;

    transactionInProgress = true;
    let hasError = false;
    let isTimeout = false;
    
    this.state.contractStaking.methods.createStake(amount, this.props.pool)
      .send(await this.buildRequestParams("createStake"))
      .catch((err)=> {

        hasError = true;
        //if by any chance we get this error, we remove the allowance key and re do it
        if(err && err.message.indexOf("amount exceeds allowance") > -1) {
          
          self.removeAllowances();
          self.setState({approved: false});
          transactionInProgress = false;

          setTimeout(function() {
            //this will force the approval path again
            UIHelper.hideSpinning();
            self.stake();
          },0);

        } else if(err && err.message.indexOf("was not mined within") > -1) {
          isTimeout = true;
          //some timeout, transaction still going on
          self.showGenericMessage("Stake error!","Unable to process stake! Hint: Network seems congested at the moment, so maybe you can try to manually speed up the transaction on Metamask.","danger");
          //do not hide the spinner, transaction was already submitted so afaik, we can just speed on on metamask
        } else {
          //another error?
          UIHelper.hideSpinning();
          transactionInProgress = false;

          let msg = "Unable to process stake! Hint: If the network seems slow you can try to manually increase the gas fees on Metamask.";
          if(err && err.message) {
            msg = err.message;
          }

          self.showGenericMessage("Stake error!", msg,"danger");
        }
      })
      .then( (result) => {

        transactionInProgress = false;

        if(!isTimeout) {
          //will hide automatically on next refresh
          UIHelper.hideSpinning();
        }
        
        if(result && result.status && result.transactionHash!=null && result.blockNumber!=null) {

          //according to the docs if we get a receipt, then the transaction was mined

          if(Number(self.state.currentStake) === 0)
            self.setState({totalParticipants: self.state.totalParticipants++});
          //immediatelly update the stake even before calling the contract method again (avoid node delay)
          //old stake + new stake
          let currentStake = (Number(self.state.currentStake) + Number(rawAmount));
          //balance - stake amount
          let balance = Number(self.state.balance) - Number(rawAmount);
          //if updated stake < max per staker
          //let canStake = (currentStake <= self.state.maxPerStaker) && (balance >= Number(self.state.minAmount) );

          //calculate locked percentage locally
          let lockedPercentage = self.getLockedPercentageLocally(Number(self.state.availableToUnstake), currentStake);

          //update totals stakes
          let totalStakes = Number(self.state.totalStakes) + Number(rawAmount);

          self.updateAllowances();
          self.setState({ totalStakes, isStakeholder: true, currentStake, balance, stakeAmount: 0, canStake: false, lockedPercentage });

          //ALL GOOD
          lastRefreshTime = new Date().getTime();
          
          self.showGenericMessage("Stake successfull!","Thank you for your stake! You will start to get rewards soon.","success");
        } else if(!hasError) {
            self.showGenericMessage("Stake error!","Unable to process stake!","danger");
        }
      });
  }

  getLockedPercentageLocally = (availableToUnstake, updatedStake) => {
    let unlockedRatio = Math.trunc(Math.abs( availableToUnstake / updatedStake) * 100);
    return (100 - unlockedRatio) + "%";
  }

  //hardcoded value
  stake = async() => {

    const { state } = this;
   
    const amount = web3.utils.toWei(String(state.stakeAmount));
    const rawAmount = Number(state.stakeAmount);
    console.log("will create stake of: " + amount + " (" + rawAmount + ") from: " + state.currentAccount);

    UIHelper.showSpinning();

    //first check component state
    let approved = this.checkIfAlreadyApproved(rawAmount);
    
    if(!approved) {
      approved = await this.approveAllowance();
      if(approved.status) {
        this.performStakeAfterApproval(amount, rawAmount);
      } else {
        UIHelper.hideSpinning();
        let msg = "Unable to approve GGTK! Hint: If the network seems slow you can try to manually increase the gas fees on Metamask.";
        if(approved.error) {
          msg = approved.error.message;
        }
        this.showGenericMessage("Approve error!",msg,"danger");
      }
    } else {
      this.performStakeAfterApproval(amount, rawAmount);
      //update the state if not already done
      if(approved && !state.approved) {
        this.setState({approved: true});
      }
    }
  }

  unstake = async() => {
    console.log("will remove my stake of " + this.state.availableToUnstake + " GGTK");

    let self = this;
    let unstakeAmount = Number(this.state.availableToUnstake);
    let currentStake = Number(this.state.currentStake);

    transactionInProgress = true;
    let hasError = false;
    let isTimeout = false;

    UIHelper.showSpinning();

    self.state.contractStaking.methods.removeStake(web3.utils.toWei(this.state.availableToUnstake), this.props.pool)
    .send(await this.buildRequestParams("removeStake"))
    .catch((err)=> {

      hasError = true;

       if(err && err.message.indexOf("was not mined within") > -1) {
         isTimeout = true;
        //some timeout
        self.showGenericMessage("Unstake error!","Unable to process unstake! Hint: Network seems congested at the moment, so maybe you can try to manually speed up the transaction on Metamask.","danger");
        //do not hide the spinner, transaction was already submitted so afaik, we can just speed on on metamask
       } else {

        UIHelper.hideSpinning();
        transactionInProgress = false;
        let msg = "Unable to process unstake! Hint: If the network seems slow you can try to manually increase the gas fees on Metamask.";
        if(err && err.message) {
          msg = err.message;
        }
        self.showGenericMessage("Unstake error!",msg,"danger");
        
       }
      
        

      }).then( (result)  => {
      //NOTE: user rejecting directly the transaction resolves the promise, does not throw any error  
     

      transactionInProgress = false;

      if(!isTimeout) {  
        UIHelper.hideSpinning();
      }
      

      if(result && result.status && result.transactionHash!=null && result.blockNumber!=null) {

        self.showGenericMessage("Unstake successfull!","Sucessfully removed your stake!","success");
        //update right away locally
        //console.log("updated stake : " + (currentStake - unstakeAmount));

        let updatedBalance = Number(self.state.balance) + unstakeAmount;
        lastRefreshTime = new Date().getTime();
        self.removeAllowances();
        
        self.setState({balance: updatedBalance, currentStake: (currentStake - unstakeAmount), availableToUnstake: 0, lockedPercentage: "100%"});
      } else if (!hasError) {
        //otherwsie this is handled above
        self.showGenericMessage("Unstake error!","Unable to process unstake!","danger");
      }

    });
  }
 
  buildRequestParams = async (contractMethod) => {
    return {from: this.state.currentAccount, ...await this.getFeesData(), ...await this.getGasEstimation(contractMethod)};
  }

  /**
   * Method to withdraw rewards and reStake them at same time
   */
  restakeRewards = async() => {
  
    //first check if we can restake
    let updatedStake = (Number(this.state.currentStake) + Number(this.state.availableRewards));
    if(updatedStake > this.state.maxPerStaker) {
      this.showGenericMessage("Restake rewards error!","Unable to process restake! Your current stake plus existing rewards is greater than the maximum amount allowed per staker. ","danger");
      return;
    }
    
    let self = this;

    UIHelper.showSpinning();

    //TODO do the same for other methods
    let params = await this.buildRequestParams("restakeRewards");

    transactionInProgress = true;
    let hasError = false;
    let isTimeout = false;

    let previousAvailable = this.state.availableRewards;

    //check approval max
    //first check component state
    let approved = this.checkIfAlreadyApproved(previousAvailable);
    
    if(!approved) {
      approved = await this.approveAllowance();
      if(!approved.status) {
        
        UIHelper.hideSpinning();
        let msg = "Unable to approve GGTK! Hint: If the network seems slow you can try to manually increase the gas fees on Metamask.";
        if(approved.error) {
          msg = approved.error.message;
        }
        this.showGenericMessage("Approve error!",msg,"danger");
        return;
      }
      
    } else {
      //update the state if not already done
      if(approved && !this.state.approved) {
        this.setState({approved: true});
      }
    }

      self.state.contractStaking.methods.restakeRewards()
        .send(params)
        .catch((err)=> {
          hasError = true;
          if(err && err.message.indexOf("was not mined within") > -1) {
            isTimeout = true;
            self.showGenericMessage("Restake rewards error!","Unable to process claim! Hint: Network seems congested at the moment, so maybe you can try to manually speed up the transaction on Metamask.","danger");
          }
          else {
            transactionInProgress = false;
            UIHelper.hideSpinning();

            let msg = "Unable to process restake rewards! Hint: If the network seems slow you can try to manually increase the gas fees on Metamask.";
            if(err && err.message) {
              msg = err.message;
            }

            self.showGenericMessage("Claim error!",msg,"danger");
          }
            
        })
        .then( (result)  => {
          //All good
          transactionInProgress = false;

          if(!isTimeout) {
            UIHelper.hideSpinning();
          }
          
          if(result && result.status && result.transactionHash!=null && result.blockNumber!=null) {

            //----------- form tsake
            //immediatelly update the stake even before calling the contract method again (avoid node delay)
            //old stake + new stake
            //let updatedStake = (Number(self.state.currentStake) + Number(previousAvailable));
            //balance - stake amount
            let balance = parseFloat(self.state.balance);
            //if updated stake < max per staker
            //let canDoStake = (updatedStake <= self.state.maxPerStaker) && (newBalance >= Number(self.state.minAmount) );

            //calculate locked percentage locally
            let lockedPercentage = self.getLockedPercentageLocally(parseFloat(self.state.availableToUnstake), updatedStake);

            //update all stakes
            let totalStakes = parseFloat(self.state.totalStakes) + parseFloat(previousAvailable);

            //ALL GOOD
            lastRefreshTime = new Date().getTime();
        
            self.updateAllowances();
            self.setState({ totalStakes, availableRewards: 0, isStakeholder: true, currentStake: updatedStake, balance, lockedPercentage});
            
            self.showGenericMessage("Restake rewards successfull!","Sucessfully restaked your " + self.state.tokenSymbol + " rewards!","success");

          } else if(!hasError) {
            //DO NOT update anything explicitly here
            self.showGenericMessage("Restake rewards error!","Unable to process restake!","danger");
          }
        });
  }

  claimRewards = async() => {
    console.log("will get my GGTK rewards ");
    let self = this;

    UIHelper.showSpinning();

    transactionInProgress = true;
    let hasError = false;
    let isTimeout = false;

    let previousAvailable = this.state.availableRewards;
    
      self.state.contractStaking.methods.withdrawReward(this.props.pool)
        .send(await this.buildRequestParams("withdrawReward"))
        .catch((err)=> {

          hasError = true;
          if(err && err.message.indexOf("was not mined within") > -1) {
            isTimeout = true;
          //some timeout, transaction still going on
            self.showGenericMessage("Claim error!","Unable to process claim! Hint: Network seems congested at the moment, so maybe you can try to manually speed up the transaction on Metamask.","danger");
          //do not hide the spinner, transaction was already submitted so afaik, we can just speed on on metamask
          }
          else {
            transactionInProgress = false;
            UIHelper.hideSpinning();

            let msg = "Unable to process claim! Hint: If the network seems slow you can try to manually increase the gas fees on Metamask.";
            if(err && err.message) {
              msg = err.message;
            }

            self.showGenericMessage("Claim error!",msg,"danger");
          }
            
        })
        .then( (result)  => {
          
          transactionInProgress = false;

          if(!isTimeout) {
            UIHelper.hideSpinning();
          }
          

          if(result && result.status && result.transactionHash!=null && result.blockNumber!=null) {

            //update available balance locally
            let balance = Number(previousAvailable) + Number(self.state.balance);

            lastRefreshTime = new Date().getTime();
           
            //>= 10 && stake < Max per staker
            //let canStake = (balance >= Number(self.state.minAmount))  && (Number(self.state.currentStake) < Number(self.state.maxPerStaker));

            this.setState({availableRewards: 0, balance });
            
            self.showGenericMessage("Claim successfull!","Sucessfully withdrawed your " + self.state.tokenSymbol + " rewards!","success");

          } else if(!hasError) {
            //DO NOT update anything explicitly here
            self.showGenericMessage("Claim error!","Unable to process claim!","danger");
          }

        });
    
  }

  convertGwei2Wei = (input) =>  {
    console.log("convert " + input + " (gwei) to (wei) => " +web3.utils.toBN(input * 1000000000) );
    return Number(web3.utils.toBN(input * 1000000000));
  }
  //from https://gasstation-mainnet.matic.network/v2
  /**
   * 
   * @returns {
      "safeLow": {
        "maxPriorityFee": 32.0805146151,
        "maxFee": 47.207049931650005
      },
      "standard": {
        "maxPriorityFee": 42.7740194868,
        "maxFee": 62.9427332422
      },
      "fast": {
        "maxPriorityFee": 53.4675243585,
        "maxFee": 78.67841655275001
      },
      "estimatedBaseFee": 14.406224111,
      "blockTime": 2,
      "blockNumber": 23983934
    }
   */

  getFeesData = async () => {
    try {
      const { networkId } = this.props;

      if(networkId == "137") {
        const response = await fetch('https://gasstation.polygon.technology/v2');
        const feesData = await response.json();
        //use "fast" instead of "standard"
        if(feesData && feesData.fast) {
          //convert gwei to wei
          const maxPriorityFeePerGas = this.convertGwei2Wei(Math.trunc(feesData.fast.maxPriorityFee));
          const maxFeePerGas = this.convertGwei2Wei(Math.trunc(feesData.fast.maxFee));
          return { maxPriorityFeePerGas, maxFeePerGas };
        }
      }

      // Arbitrum network (network ID 42161)
      if (networkId == "42161" || networkId == LOCAL_NETWORK) {
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = Math.ceil(gasPrice * 1.35); // Add 35% margin - market price
        return { gasPrice };
      }
    } catch(err) {
      console.error("Error getting fees data:", err);
    }

    return { gasPrice: 100000 };
  }

  //similar to UnySwap & PancakeSwap
  calculateGasMargin(value) {
    return web3.utils.toBN( Math.trunc( Number(value) * 120 / 100));
  }
  /**
   * Do our best shot to get some gas price estimation
   */
  getGasEstimation = async(method) => {

    let estimatedGasPrice = 0;

    try {
        switch(method) {
          case "withdrawReward":
              estimatedGasPrice = await this.state.contractStaking.methods.withdrawReward(this.props.pool).estimateGas({from: this.state.currentAccount });
              break;
          
          case "createStake":
              estimatedGasPrice = await this.state.contractStaking.methods.createStake(
                  web3.utils.toWei(String(this.state.stakeAmount)), 
                  this.props.pool
              ).estimateGas({from: this.state.currentAccount});
              break;
      
          case "approve":
              estimatedGasPrice = await this.state.contractGGTK.methods.approve(
                  this.state.contractStaking.address, 
                  this.state.maxPerStaker
              ).estimateGas({from: this.state.currentAccount});
              break;
      
          case "removeStake":
              estimatedGasPrice = await this.state.contractStaking.methods.removeStake(
                  web3.utils.toWei(this.state.availableToUnstake), 
                  this.props.pool
              ).estimateGas({from: this.state.currentAccount});
              break;
      
          case "restakeRewards":
              estimatedGasPrice = await this.state.contractStaking.methods.restakeRewards().estimateGas({from: this.state.currentAccount});
              break;
      
          default:
              // Optionally, handle unknown methods here
              console.error("Unknown method:", method);
      }
    
      let gasLimitWithMargin = this.calculateGasMargin(estimatedGasPrice);
      console.log("estimatedGasPrice for method : " + method + " is "  + estimatedGasPrice + " final gasLimit: " + gasLimitWithMargin);
      return { gasLimit: Number(gasLimitWithMargin) };
    } catch(someError) {
      console.error("Unable to get gas estimation for call " + method, someError);
      return { gasLimit: 100000 };
    }

    /*tokenContract.methods.transfer（recipientAddress，numtokens）
    .estimateGas（{from：tokenHolderAddress}，function（gasAmount）{
    console.log（gasAmount）;
    }）;*/
  }

  refreshBalance =  async () => {

    const { state } = this;

    if(isRefreshing) {
      return;
    }
    
    isRefreshing = true;
    //avoid doing this automatically at this point

    //let max = state.maxAmount;
    //let min = state.minAmount;

    try {
      await this.getTotalStakes();
      if(this.state.totalStakes > 0 && this.state.distribution > 0 && this.state.remainingRewards > 0) {
        this.calculateAPY();
      } // else shows 0 by default

      if(state.currentAccount) {

          const balance = parseFloat(web3.utils.fromWei(await callWithRetry(state.contractGGTK.methods.balanceOf(state.currentAccount))));

          const maxPossible = parseFloat(state.maxPerStaker) - parseFloat(state.currentStake);
          const maxStaking = parseFloat(state.maxStaking) - parseFloat(state.totalStakes);
          const maxAmount = Math.min(balance, maxPossible, maxStaking);

          console.log("set balance to: " + balance);
          this.setState({balance: Math.round(balance * 10000)/10000, maxAmount})

          //also get these updated
      
          this.getTotalParticipants();
          this.checkIfStakeHolder();
      }
    } catch(err) {
      console.error(err);
    } finally {
      isRefreshing = false;
    }
  }

  /*************** UI msg ****************/

  renderWarningMessageIfAny =() => {
    if(this.state.showMessage) {
      return (
      <AlertComponent 
            show={this.state.showMessage} 
            cssClasses="alert_warning"
            variant="warning" 
            title={WARNING_TITLE}
            message={WARNING_MESSAGE} closeHandler={this.closeAlertHandler} />
      )
    } 
    return null;
    
  }

  /**
   * 
   * @returns Render Alert message pre-configured
   */
  renderGenericMessageIfAny = () => {
    if(this.state.messageOptions.show) {
    
      return (
        <AlertComponent 
            show={this.state.messageOptions.show} 
            cssClasses="generic_alert"
            variant={this.state.messageOptions.variant}
            title={this.state.messageOptions.title}
            message={this.state.messageOptions.message} closeHandler={this.closeAlertHandler}/>
      )
    }
    return null;
  }
  /**
   * Options for teh generic Alerts
   * @param {} title 
   * @param {*} message 
   * @param {*} variant 
   * @returns 
   */
  showGenericMessage = (title, message, variant) => {

    let messageOptions = {};
    messageOptions.show = true;
    messageOptions.title = title;
    messageOptions.message = message;
    messageOptions.variant = variant;
    this.setState({messageOptions: messageOptions});
  }

  closeAlertHandler = () => {
    let messageOptions = {};
    messageOptions.show = false;
    this.setState({messageOptions: messageOptions, showMessage: false});
  }

  //---------- retry mechanism ----------------
  /** TODO
  performContractCall = async (method) => {

    var paramsForContractCall;
    var hasError = false;

    let gasEstimation;
  
    try {
      //gas estimations
      gasEstimation = await this.getGasEstimation(method);
  
    } catch(error) {
      console.log("Got error: ",error);
      hasError = true;
    }
  
  
    return new Promise( (resolve, reject) => {
  
      if(hasError) {
        console.log("Will reject immediately");
        resolve("NOK");
      } else {
  
        console.log("Running contract call/method: " + method);
        
      }
      
    
    });
  } */

	
}

export default StakePage;
