import React, { Component } from "react";
import {Button } from "react-bootstrap";
import HeaderPage from "./HeaderPage";
import FooterPage from "./FooterPage";

class Underconstruction extends Component {
    render() {
      const { props } = this;
      const { web3 } = window;

      let error_msg = "";
      let msg = "";
      if(!web3 || props.wrongNetwork) {
          if(!web3) {
              error_msg = "No Ethereum wallet detected.";
          } else if(props.wrongNetwork) {
              error_msg = "Wrong GGDAPP network! Please check your WalletProvider/Metamask connection.";
          }
      } 
      else if(!props.hasMetamask) {
          error_msg = "Please install Metamask wallet extension first.";
      }
      else if(!props.isLoggedIn) {
          error_msg = "Please connect your Metamask wallet first.";
      }

      console.log("error message: " + error_msg);
      console.log("has metamask: " + props.hasMetamask);
      
      let hasError = (error_msg.length > 0);

      return (
          <div className="main stake">
          <HeaderPage title="GGDAPP" subtitle=""/>  
          <div className="box small-margin-top">
              <div className="error_div">{error_msg || msg}
                  <div className="connect-metamask">
                      {this.printFurtherHelp(hasError)}
                  </div>
                  {!props.isLoggedIn  ?
              <Button onClick={this.connectMetamask} className="connect-btn btn-stake">Connect Wallet</Button> : null }
              </div>
          </div>
          <FooterPage/>  
          </div>
      )
    }

    printFurtherHelp(hasError) {
      const { props } = this;
      const network_name = props.networkId === process.env.REACT_APP_POLYGON_NETWORK_ID ? "Polygon" : "Arbitrum";

      if(hasError) {
        if(props.wrongNetwork && props.hasMetamask) {
          return (
              <div>
              <div className="btn-full-width">
                <p>Please install a valid wallet provider like Metamask extension and use a supported browser: Chrome/Brave/Firefox</p>
                <p>If you think everything is correct but you still don´t see the page, make sure you are connected to the <em>{network_name} network</em> in Metamask.</p>
              </div>
              <p><Button onClick={this.switchNetwork} className="connect-btn btn-stake">Switch Network</Button></p>
              </div>
              )
        } else {
            return (
              <div className="btn-full-width">
                <p>Please install a valid wallet provider like Metamask extension and use a supported browser: Chrome/Brave/Firefox</p>
                <p>If you think everything is correct but you still don´t see the page, make sure you are connected to the <em>{network_name} network</em> in your wallet provider.</p>
                <p><Button onClick={this.clearModalWeb3Cache} className="connect-btn btn-stake">Try Again</Button></p>
              </div>
            )
        }
      }
      return "";
    }

    clearModalWeb3Cache = async () => {
      localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
      window.history.back();
    }

    switchNetwork = async () => {
      const { props } = this;
      const { ethereum } = window;
      
      if (!ethereum) {
        console.log("Ethereum object doesn't exist!");
        return;
      }
    
      const targetChainId = props.web3.utils.toHex(props.networkId);
      const isPolygon = props.networkId === process.env.REACT_APP_POLYGON_NETWORK_ID;
    
      const networkParams = isPolygon
        ? {
            chainId: "0x89",
            chainName: "Polygon",
            rpcUrls: ["https://polygon-rpc.com"],
            nativeCurrency: {
              name: "Matic",
              symbol: "Matic",
              decimals: 18,
            },
          }
        : {
            chainId: "0xA4B1",
            chainName: "Arbitrum One Mainnet",
            rpcUrls: ["https://arb1.arbitrum.io/rpc"],
            blockExplorerUrls: ["https://arbiscan.io/"],
            nativeCurrency: {
              name: "ETH",
              symbol: "ETH",
              decimals: 18,
            },
          };
    
      try {
        // switch to the network
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: targetChainId }],
        });

        console.log("Successfully switched network");
        window.location.reload();
      } catch (error) {
        console.log("Error adding or switching network:", error);

        // add the network
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkParams],
        });
        console.log("Network added successfully");

        // switch to the network
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: targetChainId }],
        });

        console.log("Successfully switched network");
        window.location.reload();
      }
    };    
    
    connectMetamask = async () => {
      try {
        const { ethereum } = window;
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        await ethereum.request({ method: "eth_requestAccounts" });
        //we use eth_accounts because it returns a list of addresses owned by us.
        const accounts = await ethereum.request({ method: "eth_accounts" });
        if(accounts.length > 0) {
            console.log("OK got accounts " + accounts[0]);
        }
        
        //redirect
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    } 

    /**printDownloadOrLoginWithMetamask() {
    
        if(!this.state.hasMetamask) {
            return <Button onClick={ (e) => {this.downloadMetamask(e)}} className="connect-btn btn-full-width btn-min-height40">Click here to install MetaMask!</Button>
        }
        if(!this.state.isLoggedIn) {
            return <Button onClick={ (e) => {this.connectMetamask(e)}} className="connect-btn btn-full-width btn-min-height40">Connect wallet</Button>
        }
        return "";
    }

    connectMetamask = async () => {
        try {

            const { ethereum } = window;
            // Will open the MetaMask UI
            // You should disable this button while the request is pending!
            await ethereum.request({ method: 'eth_requestAccounts' });
            //we use eth_accounts because it returns a list of addresses owned by us.
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            if(accounts.length > 0) {
                console.log("OK got accounts " + accounts[0]);
                this.setState({isLoggedIn: true});
            }
            
            //redirect

          } catch (error) {
            console.error(error);
          }
    } 

    */
}

export default Underconstruction;
