import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import MainPage from "./MainPage";
import PlayCatalogPage from "./PlayCatalogPage";
import InvestPage from "./InvestPage";
import StakeOptions from "./StakeOptions";
import TradeOptions from "./TradeOptions";
import DrizzleLoader from './DrizzleLoader';
import Pirates from './Pirates';
import SimThunder from './Simthunder';
import GamingPools from './GamingPools';
import AboutPage from './AboutPage';
import ChangeLogPage from './ChangeLogPage';
import CryptoChaser from './CryptoChaser';
import Neeon from './Neeon';

class RouterPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            drizzle: props.drizzle,
            drizzleState: props.drizzleState,
        }

    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/">
                        <MainPage/>
                    </Route>
                    <Route exact path="/play">
                        <PlayCatalogPage/>
                    </Route>
                    <Route exact path="/invest">
                        <InvestPage/>
                    </Route>
                    <Route exact path="/stake/:network/:pool" component={DrizzleLoader}/>
                    <Route exact path="/stake_options">
                        <StakeOptions/>
                    </Route>
                    <Route exact path="/trade_options">
                        <TradeOptions/>
                    </Route>
                    <Route exact path="/pirates">
                        <Pirates/>
                    </Route>
                    <Route exact path="/simthunder">
                        <SimThunder/>
                    </Route>
                    <Route exact path="/neeon">
                        <Neeon />
                    </Route>
                    <Route exact path="/cryptochaser">
                        <CryptoChaser/>
                    </Route>
                    <Route exact path="/gaming_pools">
                        <GamingPools/>
                    </Route>
                    <Route exact path="/about">
                        <AboutPage/>
                    </Route>
                    <Route exact path="/changelog">
                        <ChangeLogPage hideFooter="true"/>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default RouterPage;