import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class InvestPage extends React.Component{
  render() {
    return  (
      <div className="main invest">
        <HeaderPage title="Invest" subtitle="INVESTING"/>  
        <div className="box">
          <Link className="outerBox mainBox4" to="/stake_options">
            <div className="innerBox">
                <div className='box-frame-title'><span>Stake GGTK</span></div>
            </div>  
          </Link>
          <Link className="outerBox mainBox3" to="/trade_options">
          <div className="innerBox">
              <div className='box-frame-title'><span>Buy GGTK</span></div>
          </div>
          </Link>
        </div>
        <FooterPage/>
      </div>
    );
  }
}

export default InvestPage;
