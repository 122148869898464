import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class GamingPools extends React.Component{
  render() {
    return  (
      <div className="main">
      <HeaderPage title="Gaming Pools" subtitle="Gaming Pools"/>  
      <div className="box">
        <Link className="outerBox mainBox9" to="/stake/polygon/1">
          <div className="innerBox">
            <div className='box-frame-title'><span>SimRacer Coin Pool</span></div>
          </div>
        </Link>
        <Link className="outerBox mainBox8" to="/stake/polygon/3">
          <div className="innerBox">
                <div className="box-frame-title">
                  <div><span>Pirates 2048 Pool</span></div>
                </div>
          </div>
        </Link>
        {/*<Link className="outerBox mainBox10" to="/stake/4">
          <div className="innerBox">
                <div className="box-frame-title">
                  <div>Crypto Chaser Pool</div>
                </div>
          </div>
    </Link>*/}
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default GamingPools;
