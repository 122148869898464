import React from 'react';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'
  
class ChangeLogPage extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      changelog: {}, 
      hidefooter: props.hidefooter
    }
    
  }

  componentDidMount = async () => {
    let self = this;
    try {
      
      fetch('../../changelog.json', {
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }).then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        self.setState({changelog: myJson});
      });
    } catch (error) {
        console.log('error',error);
    }
  }

  renderChangelog() {
    if(this.state.changelog) {
      if(this.state.changelog.length > 0) {
		    let list = [];
        let listItems = [];

        for(var i = 0; i < this.state.changelog.length; ++i) {
          var change = this.state.changelog[i];
          if(!list[change.tag]) list[change.tag] = [];
          list[change.tag].push(<li>{change.title}</li>);
        }

        for(var change in list) {
          listItems.push(<div><h5>{change}</h5><ul>{list[change]}</ul></div>);
        }
        
        return (
          <div>
          {listItems}
          </div>
        );
      }
    }
    return "N/A";
  }

  render() {
    return  (
      <div className="main about-box-main">
      <HeaderPage title="Changelog" subtitle="GG DApp Open Beta Changelog"/>  
      <div className="box box-about box-changelog">
        <div className='inner-box-about inner-box-changelog' id='inner-box-changelog'>
        <h4>GGDAPP is always evolving. Here you can have an idea of what our team is doing.</h4>
        {this.renderChangelog()}
        </div>
    
      </div>
      <FooterPage hideFooter="true"/>  
      </div>
    );
  }
}

export default ChangeLogPage;
