import '../css/App.css';
import '../css/main.css';
import React from 'react';
import { Modal } from 'react-bootstrap'; // Check out drizzle's react components at @drizzle/react-components
import HeaderPage from './HeaderPage';
import FooterPage from './FooterPage';
import Carousel from "react-simply-carousel";

class Neeon extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      activeSlideIndex: 0,
      showModal: false,
      modalBackground: ""
    }
  }

  setActiveSlideIndex = (newActiveSlideIndex) => {
    this.setState({
      activeSlideIndex: newActiveSlideIndex,
    });
  };

  renderSlides = () => {
    const commonStyle = {
      background: "transparent",
      color:"#333",
      border: 'none',
      width: 60,
      height: 60,
      minWidth: 60,
      alignSelf: "center"
    }

    return (
      <Carousel
        activeSlideIndex={this.state.activeSlideIndex}
        onRequestChange={this.setActiveSlideIndex} 
        forwardBtnProps={{
          children: ">",
          style: commonStyle
        }} 
        backwardBtnProps={{
          children: "<",
          style: commonStyle
        }}
        itemsToShow={1}
        itemsToScroll={1} 
        resposiveProps={[{maxWidth: 767, itemsToShow: 2}]} //only show 1 tem when we go to 768px or less
      >
        <div className="inner_slide neeon_slide_0" onClick={(e)=>this.openSlider(0)}></div>
        <div className="inner_slide neeon_slide_1" onClick={(e)=>this.openSlider(1)}></div>
        <div className="inner_slide neeon_slide_2" onClick={(e)=>this.openSlider(2)}></div>
        <div className="inner_slide neeon_slide_3" onClick={(e)=>this.openSlider(3)}></div>
 
      </Carousel>
    )
  }

  openSlider = (index) => {
    let back = "neeon_slide_" + index;
    this.setState({showModal: true, modalBackground: back, activeSlideIndex: index});
  }

  handleClose = () => {
    this.setState({showModal: false});
  }

  changeSlider = (sliderIndex) => {
    let back = "neeon_slide_" + sliderIndex;
    this.setActiveSlideIndex(sliderIndex);
    this.setState({modalBackground: back});
  }

  movePrevious = () => {
    let current = this.state.activeSlideIndex;
    if(current === 0) {
      current = 3;
    } else {
      current -=1;
    }
    this.changeSlider(current);
  }

  moveNext = () => {
    let current = this.state.activeSlideIndex;
    if(current === 3) {
      current = 0;
    } else {
      current +=1;
    }
    this.changeSlider(current);
  }

  render() {
    return  (
      <div className="main main-games">
      <HeaderPage title="GGDAPP" subtitle="Neeon"/>  

      <Modal show={this.state.showModal} 
      onHide={this.handleClose} 
      centered size="lg" className='game-modal'>
        <Modal.Body>
            <div onClick={this.movePrevious} className='modal-content-previous'>&lt;</div>
            <div onClick={this.handleClose} className={`modal_img_container ${this.state.modalBackground}`}></div>
            <div onClick={this.moveNext} className='modal-content-next'>&gt;</div>
        </Modal.Body>
      </Modal>

      <div className="box">
        <div className="games_container">
          <div className="game_info">
              <div className="first_col pt-2">
                <div className="neeon_icon mb-2"></div>
                <a className="button_dapp" target="_blank" href="https://neeon.ggdapp.com" rel="noreferrer">Open Dapp</a>
              </div>
              <div className="second_col">
                <h2>Neeon</h2>
                <p>Neeon is a fast-paced platformer, minimalistic but challenging, needing precise timings, focus and quick reflexes to avoid obstacles. Prove you're the best and earn weekly prizes!</p>
                <p>Every Monday at 0:00 UTC there is a prize pool distribution among the top-10 scorers that includes 80% of all credit sales.</p>
                {/*<p className="p-row">
                  <a target="_blank" href="https://twitter.com/SimracerCoin"><div className="social_min twitter_min"></div></a>
                  <a target="_blank" href="https://discord.gg/Keuj2uP"><div className="social_min discord_min"></div></a>
                </p>*/}
              </div>
          </div>
          <div className="game_media pt-2">
            <div className="slides-container">
            {this.renderSlides()}
            </div>
          </div>
        </div>
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default Neeon;
