import React from 'react';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class AboutPage extends React.Component{
  render() {
    return  (
      <div className="main about-box-main">
      <HeaderPage title="About" subtitle="About GG DApp Open Beta"/>  
      <div className="box box-about">
        <div className='inner-box-about'>
        <p>GG DApp is an upcoming gaming platform plus DeFi ecosystem, with NFTs at its core, that aims to reward players, developers and investors. With the current beta we want to give the users a preview of the UI and how the rewards mechanism will work. 
        To keep it simple, we adopted a similar model to Bitcoin rewards, with a total of 21 million GGTK available. 
        Every 10 minutes 50 GGTK will be distributed and roughly every four years, the total number of GGTK users of GG DApp can potentially win is halved.
        </p>
        <p>This 50 GGTK will be distributed to the general pool and also to the individual pools of each gaming project in the platform, 20% to the general pool and the rest 80% to the gaming pools. At this point there is only the general pool available for staking and so 10 GGTK will be distributed every 10 minutes to this pool. 
        </p>
        <p>Due to the congestion problems in Polygon network we do the rewards distribution once a day. The amount remains 1440 GGTK/day.
        </p>
        <p>This model is still experimental and will be optimized according to insights collected from the integration in our own projects and feedback from users, as a consequence, changes to this model may occur.
        </p>
        </div>
    
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default AboutPage;
