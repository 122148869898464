import React from 'react';
import RouterPage from "./pages/RouterPage";

class App extends React.Component{  
  render() {
    return (
      <RouterPage />
    )
  }
}

export default App;
