import '../css/App.css';
import '../css/main.css';
import React from 'react';
import { Link } from 'react-router-dom';
import HeaderPage from './HeaderPage'
import FooterPage from './FooterPage'

class CryptoChaser extends React.Component{
  render() {
    return  (
      <div className="main main-games">
      <HeaderPage title="GGDAPP" subtitle="Crypto Chaser"/>  
      <div className="box">
        <div className="games_container">
          <div className="game_info sim-thunder">
              <div className="first_col sim-thunder-col">
                {/*<div className="simthunder_icon"></div>*/}
                <a className="button_dapp" target="_blank" href="https://cryptochaser.io/" rel="noreferrer">Open Dapp</a>
                <Link className="button_dapp" to="/stake/polygon/3">Staking pool</Link>
              </div>
              <div className="second_col">
                <h2>Crypto Chaser</h2>
                Crypto Chaser is a browser-based, single-player, arcade game set in a digital cosmos, combining elements of action, strategy, and cryptocurrency rewards. Navigate the vast universe, collect crypto fragments, and unlock daily token rewards and ultra-rare NFTs.
                {/*<p className="p-row">
                  <a target="_blank" href="https://twitter.com/SimracerCoin"><div className="social_min twitter_min"></div></a>
                  <a target="_blank" href="https://discord.gg/Keuj2uP"><div className="social_min discord_min"></div></a>
                </p>*/}
              </div>
          </div>
          <div className="game_media sim-thunder">
            {/*this.renderSlides()*/}
            <div className="logo-big-cryptochaser"></div>
            
          </div>
        </div>
      </div>
      <FooterPage/>  
      </div>
    );
  }
}

export default CryptoChaser;
